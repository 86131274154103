<template>
  <Tasks/>
</template>


<script>
export default {
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Tasks", path:"", isActive: true}] 
    }
  },
  components: {
    Tasks: () => import("../../src/components/tasks/Tasks"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>